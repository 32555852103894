import React, { useState, useEffect, Suspense } from 'react'

import SEO from '../components/seo'
import Layout from '../components/Layout'
import Button from 'antd/lib/button'
//import Modal from 'react-modal'
import 'antd/lib/button/style/css'
import './styles/index.scss'
/*
import { Link } from 'gatsby'
import modalSaasCloud from '../images/modal-saas-cloud.svg'
import modalSelfDeploy from '../images/modal-self-deploy.svg'
*/
/*
import improveMobile from '../images/improve-mobile.svg'
import improveRetention from '../images/retro-retention-box.svg'
import improvePaths from '../images/retro-paths-box.svg'
import improveFunnels from '../images/retro-funnels-box.png'
import enterprise01 from '../images/retro-self-managed.svg'
import enterprise02 from '../images/enterprise-02.svg'
import enterprise03 from '../images/enterprise-03.svg'
import coolHedgehog from '../images/cool-hedgehog.svg'
import retroAutocapture from '../images/retro-autocapture.svg'
*/
import autocrop_diagram from '../images/autocrop-diagram.png'
import dim_slab from '../images/dim-slab.gif'
import search_home from '../images/search-features-searchhome.svg'
import search_harvest from '../images/search-features-harvest.svg'
import search_filter from '../images/search-features-filter.svg'
import search_collab from '../images/search-features-collab.svg'
/*
import trafficFlow from '../images/retro-how-traffic-flows.svg'
import visualizeTrends from '../images/retro-product-trends.svg'
import retroFlagsImg from '../images/retro-feature-flags.svg'
import selfHostedImg from '../images/self-host.svg'
import sessionRecordingImg from '../images/session-recording-3.svg'
*/
import { FeaturedSectionTextLeft } from '../components/Sections/FeaturedSectionTextLeft'
import { FeaturedSectionTextRight } from '../components/Sections/FeaturedSectionTextRight'
//import { FeaturedSectionTripleImage } from '../components/Sections/FeaturedSectionTripleImage'
import { Spacer } from '../components/Spacer'
//import { DesignedForYourStackBlock } from '../components/Sections/DesignedForYourStackBlock'
const UserLogosCarousel = React.lazy(() => import('../components/UserLogosCarousel'))

/*
const GetStartedModalContent = () => (
    <>
        <h2>Try PostHog for free</h2>
        <div className="modalCardsWrapper">
            <a href="https://app.posthog.com/signup">
                <div className="modalSaasCloud modalCard">
                    <div className="modalCardHeader">
                        <img src={modalSaasCloud} alt="modal-saas-cloud" />
                        <h2>Cloud</h2>
                    </div>
                    <h4>Small business or low volumes and don't want hassle?</h4>
                    <p>This is the simplest way to get started. Create an account.</p>
                </div>
            </a>
            <Link to="/docs/deployment">
                <div className="modalSelfDeploy modalCard">
                    <div className="modalCardHeader">
                        <img src={modalSelfDeploy} alt="modal-self-deploy " />
                        <h2>Open Source</h2>
                    </div>
                    <h4>Want to use our free open source product?</h4>
                    <p>Deploy PostHog Open Source on your own infrastructure. Free forever.</p>
                </div>
            </Link>
            <a href="mailto:sales@posthog.com">
                <div className="modalSelfDeploy modalCard">
                    <div className="modalCardHeader">
                        <img src={modalSelfDeploy} alt="modal-self-deploy " />
                        <h2>Enterprise</h2>
                    </div>
                    <h4>10K+ users? Need support?</h4>
                    <p>Managed on your infrastructure with greater scalability and support. Book a pilot.</p>
                </div>
            </a>
        </div>
    </>
)
*/

function IndexPage() {
    //const [modalIsOpen, setModalIsOpen] = useState(false)
    const [carouselAvailable, setCarouselAvailable] = useState(false)

    useEffect(() => {
        if (window && !carouselAvailable) {
            setCarouselAvailable(true)
        }
    }, [])

    const CarouselFallback = () => <div style={{ height: 60 }}></div>

    return (
        <div className="homepage">
            <div className="indexContainer">
                <Layout containerStyle={{ maxWidth: 'auto', padding: 0 }} isHomePage={true}>
                    <SEO
                        title="Aiprentice Artifact - Search all your drawings"
                        description="Search relavent drawings from your library of past projects with a few keystrokes. Preserving your institutional memory and pass it on. Create and update interior elevation outlines by one click."
                    />
                    <div className="topSectionWrapperMobile">
                        <div className="gradient"></div>
                        <div className="topPageWrapper wrapper">
                            <Spacer height={35} onlyDesktop={true} />
                            {/*                            <div className="pageHeader row">
                                <div className="copy half">
                                    <h2>
                                        Find that detail in 1 sec,
                                        <br />
                                        from your past projects
                                    </h2>
                                </div>
                            </div>
                            */}
                            <div className="topPageRow row">
                                <div className="topPageCol1">
                                    <div className="copy half">
                                        <h2>Find the best detail with Artifact, in 1 sec</h2>
                                    </div>

                                    {/*
                                    <div className="joinUsersText">
                                        <p>
                                            Join 2,700 companies <br className="hiddenBreak" /> using PostHog.
                                        </p>
                                        <br />
                                    </div>
                                    */}
                                    <div className="joinUsersButtons">
                                        <a href="https://app.aiprentice.com/login">
                                            <Button
                                                type="secondary"
                                                size="large"
                                                className="getStarted"
                                                //onClick={() => setModalIsOpen(true)}
                                            >
                                                Get Started for Free
                                            </Button>
                                        </a>
                                        {/*<Modal
                                            isOpen={modalIsOpen}
                                            onRequestClose={() => setModalIsOpen(false)}
                                            className="modalContent"
                                            overlayClassName="modalOverlay"
                                        >
                                            <GetStartedModalContent />
                                            <Button
                                                icon="close"
                                                onClick={() => setModalIsOpen(false)}
                                                className="modalClose"
                                            />
                                        </Modal>*/}
                                        <a href="/request_demo">
                                            <Button type="primary" size="large" className="requestDemo">
                                                Request Demo
                                            </Button>
                                        </a>
                                    </div>
                                </div>
                                <div className="topPageCol2" />
                            </div>
                        </div>
                    </div>

                    <Spacer />
                    <h3 className="centered">Used At</h3>
                    {carouselAvailable ? (
                        <Suspense fallback={<CarouselFallback />}>
                            <UserLogosCarousel />
                        </Suspense>
                    ) : (
                        <CarouselFallback />
                    )}

                    <Spacer />
                    <Spacer />
                    <FeaturedSectionTextLeft
                        headerText="Start with a wealth of knowledge your team amassed over years"
                        listItem="01"
                        descriptionText="Don't start from a blank page. From now on for every detail you are to draw, start with this wealth of knowledge your team has accumulated over the years."
                        image={search_home}
                        color="red"
                        imgDesktopWidth="30vw"
                        imgDesktopHeight="200px"
                        imgStyle={{ backgroundSize: 'contain', marginTop: 80, marginLeft: 50 }}
                    ></FeaturedSectionTextLeft>

                    <FeaturedSectionTextRight
                        headerText="Harvest a past project with one click"
                        listItem="02"
                        descriptionText="
                            Drawings are put on the shelf after a project is built. Knowledge must not. Click a button to lift these drawings, so
                            they become just a few keystrokes away."
                        image={search_harvest}
                        imgDesktopWidth="12vw"
                        color="yellow"
                        imgStyle={{ backgroundSize: 'contain', marginLeft: '30vw' }}
                    />

                    <FeaturedSectionTextLeft
                        headerText="Find relevant details from your past projects in one second"
                        listItem="03"
                        descriptionText="Using filters and search to get down to a few details where it has been figured out."
                        image={search_filter}
                        imgDesktopWidth="40vw"
                        color="blue"
                        imgStyle={{ backgroundSize: 'contain', marginLeft: 70 }}
                    />

                    <FeaturedSectionTextRight
                        headerText="Collaborate"
                        listItem="04"
                        descriptionText="Create a personal collection of details to share and collaborate with your team."
                        image={search_collab}
                        imgDesktopWidth="40vw"
                        color="red"
                        imgStyle={{ backgroundSize: 'contain', marginLeft: '10vw' }}
                    />

                    <Spacer onlyDesktop={true} />
                    {/* */}

                    <Spacer />
                    <div style={{ width: '70vw', margin: 'auto' }}>
                        <h3>Construction documents automation</h3>
                        <p className="fs-paragraph" style={{ fontSize: '18' }}>
                            We experienced, personally, the lack of automation and standardization in documentation
                            processes of the AEC industry. The challenges seem common, and many are missing pieces that
                            nobody else is building.
                        </p>
                        <p className="fs-paragraph" style={{ fontSize: '18' }}>
                            Thus, we build them along the way.
                        </p>
                    </div>

                    <FeaturedSectionTextRight
                        headerText="Automated interior elevation outlines"
                        listItem="01"
                        descriptionText=""
                        image={autocrop_diagram}
                        color="yellow"
                        imgStyle={{ backgroundSize: 'contain' }}
                    >
                        <p className="fs-paragraph" style={{ fontSize: '18' }}>
                            Interior elevation outlines are time-consuming to create, and require care when design
                            changes, again and again. AutoCrop Revit<sup>&reg;</sup> add-in takes care of creating and
                            updating it in one click. Yes, it sets the line style too, if you use a filled region.
                        </p>
                    </FeaturedSectionTextRight>
                    <Spacer onlyDesktop={true} />
                    <Spacer />
                    <FeaturedSectionTextLeft
                        headerText="Dimension Assistant"
                        listItem="02"
                        descriptionText="Real-time red-lining of elements not yet located, giving you a quantitative, specific, up-to-the-moment display of the amount of work remaining."
                        image={dim_slab}
                        color="red"
                        imgDesktopHeight={277}
                        imgDesktopWidth="30vw"
                        imgStyle={{ backgroundSize: 'contain' }}
                    />
                    <Spacer onlyDesktop={true} />
                    {/*
                    <Spacer onlyDesktop={true} />
                    <FeaturedSectionTextRight
                        headerText="Visualize product trends and retention"
                        listItem="03"
                        descriptionText="Powerful analytics to really understand what your users are doing and how to keep
                    them coming back."
                        image={visualizeTrends}
                        color="navy"
                    />
                    <Spacer onlyDesktop={true} />
                    <FeaturedSectionTextLeft
                        headerText="Watch how real users use your product"
                        listItem="04"
                        descriptionText="Watch back sessions from real users to track down bugs
                        faster and see where your customers get stuck."
                        image={sessionRecordingImg}
                        color="yellow"
                    />
                    <Spacer onlyDesktop={true} />
                    <FeaturedSectionTripleImage
                        mobileImg={improveMobile}
                        img1={improveRetention}
                        img2={improveFunnels}
                        img3={improvePaths}
                        listItem="05"
                        headerText="Improve conversion rates"
                        descriptionText={`Visualize how users navigate through your app or website, and use metrics to understand what needs improvement.`}
                        color="red"
                        title1="Retention"
                        title2="Funnels"
                        title3="Paths"
                    />
                    <Spacer />
                    <Spacer />
                    <div className="fs-feature-flags-wrapper">
                        <FeaturedSectionTextLeft
                            headerText="Use feature flags to test new ideas"
                            listItem="06"
                            descriptionText="Roll out new features to a few users first, randomly or based on their previous behavior or profile information."
                            image={retroFlagsImg}
                            color="navy"
                        />
                    </div>
                    <DesignedForYourStackBlock />
                    <Spacer />
                    <div className="fs-self-host-wrapper">
                        <FeaturedSectionTextLeft
                            headerText="Self-host PostHog with full data access"
                            listItem=""
                            descriptionText="Huge user base? No problem. Eliminate the data protection risks and costs associated with sending millions of users' data to third parties."
                            image={selfHostedImg}
                            color="blue"
                        />
                    </div>
                    */}

                    {/* Why posthog */}
                    {/*
                    <div className="whyPosthogWrapper wrapper">
                        <div className="whyPosthogRow row">
                            <h1>PostHog versus Self-Build</h1>
                            <div className="whyPosthogElementsWrapper">
                                <div className="whyPosthogElement">
                                    <h2 className="gosha">Data Lake</h2>
                                    <div className="whyPosthogElementBody">
                                        <p>Suitable for scalability</p>
                                        <p>You own your own data</p>
                                        <p>Resource-intensive</p>
                                        <p>Requires technical knowledge</p>
                                        <p>You have to build your own features</p>
                                        <p>Diverts effort from your core business</p>
                                    </div>
                                </div>
                                <div className="whyPosthogElement">
                                    <h2 className="gosha">Deploying Posthog</h2>
                                    <div className="whyPosthogElementBody">
                                        <p>Suitable for scalability</p>
                                        <p>You own your own data</p>
                                        <p>Ready to use immediately</p>
                                        <p>Regular updates</p>
                                        <p>Feature-rich</p>
                                        <p>Can be integrated with a data lake</p>
                                    </div>
                                </div>
                            </div>
                            <div className="startTrialRow">
                                <Button
                                    type="primary"
                                    className="startTrialButton"
                                    onClick={() => setModalIsOpen(true)}
                                >
                                    Start my 30-day free trial
                                </Button>
                            </div>
                        </div>
                    </div>
                    */}

                    {/* posthog for enterprise */}

                    {/*
                    <div className="posthog4EnterpriseWrapper wrapper redbackground">
                        <div className="posthog4EnterpriseRow1 row">
                            <h2 className="gosha ph-enterprise-title">PostHog for Enterprise</h2>
                        </div>
                        <div className="posthog4EnterpriseRow2 row">
                            <div className="posthog4EnterpriseRow2Col">
                                <h4 className="ph-enterprise-subheader">Self-managed</h4>
                                <img alt="enterprise-01" loading="lazy" src={enterprise01} className="imageShow" />
                                <div className="enterpriseText">
                                    <p>
                                        PostHog can be deployed in your cloud, for painless adoption and onboarding,
                                        with full underlying data access.
                                    </p>
                                </div>
                            </div>
                            <div className="posthog4EnterpriseRow2Col">
                                <h4 className="ph-enterprise-subheader">Unlimited volume</h4>
                                <img alt="enterprise-02" loading="lazy" src={enterprise02} className="imageShow" />
                                <div className="enterpriseText">
                                    <p>PostHog is built to scale. That includes our open core pricing model.</p>
                                </div>
                            </div>
                            <div className="posthog4EnterpriseRow2Col">
                                <h4 className="ph-enterprise-subheader">Support</h4>
                                <img alt="enterprise-03" loading="lazy" src={enterprise03} className="imageShow" />
                                <div className="enterpriseText">
                                    <p>
                                        PostHog's team can offer instance monitoring, updates and help with supporting
                                        integrations.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Spacer />
                    */}
                    {/*
                    <Spacer />
                    <FeaturedSectionTextLeft
                        listItem=""
                        headerText="Get started for free"
                        image={coolHedgehog}
                        color="blue"
                        imgDesktopHeight={300}
                        descriptionText={
                            <div>
                                <p>
                                    Self-host our free-forever open source offering or start tracking your product
                                    instantly with PostHog Cloud.
                                </p>
                                <div className="landing-page-final-cta">
                                    <Button type="primary" size="large" onClick={() => setModalIsOpen(true)}>
                                        Get Started for Free
                                    </Button>
                                    <a href="/slack">
                                        <Button type="primary" size="large">
                                            Join Our Slack
                                        </Button>
                                    </a>
                                </div>
                            </div>
                        }
                    />
                    */}
                </Layout>
            </div>
        </div>
    )
}

export default IndexPage
